import type { App } from 'vue';
import MosaicCard from './mosaic-card/MosaicCard.vue';
import MosaicSaveCard from './mosaic-card/MosaicSaveCard.vue';
import MosaicCreateEditCardPage from './pages/MosaicCreateEditCardPage.vue';
import MosaicMultiSectionCard from './mosaic-card/MosaicMultiSectionCard.vue';
import MosaicStepperCard from './mosaic-card/MosaicStepperCard.vue';
import MosaicCardTitle from './mosaic-card/MosaicCardTitle.vue';
import MosaicCardHeading from './mosaic-card/MosaicCardHeading.vue';
import MosaicCardSubheading from './mosaic-card/MosaicCardSubheading.vue';
import MosaicDisabledTooltip from './helpers/MosaicDisabledTooltip.vue';
import MosaicPagination from './pagination/MosaicPagination.vue';
import MosaicLoadErrorCard from './loading/MosaicLoadErrorCard.vue';
import MosaicLoadError from './loading/MosaicLoadError.vue';
import MosaicAutocomplete from './inputs/MosaicAutocomplete.vue';
import MosaicCheckbox from './inputs/MosaicCheckbox.vue';
import MosaicDatePicker from './inputs/MosaicDatePicker.vue';
import MosaicColorPicker from './inputs/MosaicColorPicker.vue';
import MosaicPasswordTextField from './passwords/MosaicPasswordTextField.vue';
import MosaicPasswordSecurityLink from './passwords/MosaicPasswordSecurityLink.vue';
import MosaicPasswordHelp from './passwords/MosaicPasswordHelp.vue';
import MosaicSelect from './inputs/MosaicSelect.vue';
import MosaicTextArea from './inputs/MosaicTextArea.vue';
import MosaicTextField from './inputs/MosaicTextField.vue';
import MosaicQuillField from './inputs/MosaicQuillField.vue';
import MosaicFileUploadField from './inputs/MosaicFileUploadField.vue';
import MosaicJudgementSelect from './inputs/MosaicJudgementSelect.vue';
import MosaicJudgementSetSelect from './inputs/MosaicJudgementSetSelect.vue';
import MosaicTooltipChip from './display/MosaicTooltipChip.vue';
import MosaicTitle from './headers/MosaicTitle.vue';
import MosaicExpandableUserList from './mosaic-list/MosaicExpandableUserList.vue';
import MosaicList from './mosaic-list/MosaicList.vue';
import MosaicListItem from './mosaic-list/MosaicListItem.vue';
import MosaicTabItemList from './mosaic-list/MosaicTabItemList.vue';
import MosaicListFiltersCard from './mosaic-list/MosaicListFiltersCard.vue';
import MosaicLoadingCard from './loading/MosaicLoadingCard.vue';
import MosaicButtonWithMenu from './buttons/MosaicButtonWithMenu.vue';
import MosaicPreviousAndNextArrowButtons from './buttons/MosaicPreviousAndNextArrowButtons.vue';
import MosaicButton from './buttons/MosaicButton.vue';
import MosaicIconButton from './buttons/MosaicIconButton.vue';
import MosaicIconButtonNumber from './buttons/MosaicIconButtonNumber.vue';
import MosaicDeleteIconButton from './buttons/MosaicDeleteIconButton.vue';
import MosaicErrorAlert from './feedback/MosaicErrorAlert.vue';
import MosaicSnackbar from './feedback/MosaicSnackbar.vue';
import MosaicErrorSnackbar from './feedback/MosaicErrorSnackbar.vue';
import MosaicSuccessSnackbar from './feedback/MosaicSuccessSnackbar.vue';
import MosaicDialog from './dialogs/MosaicDialog.vue';
import MosaicSaveDialog from './dialogs/MosaicSaveDialog.vue';
import MosaicDeleteDialog from './dialogs/MosaicDeleteDialog.vue';
import MosaicConfirmCohortActionUpdateDialog from './dialogs/MosaicConfirmCohortActionUpdateDialog.vue';
import MosaicRadioButtons from './inputs/MosaicRadioButtons.vue';
import MosaicIcon from './display/icons/MosaicIcon.vue';
import MosaicInfoIcon from './display/icons/MosaicInfoIcon.vue';
import MosaicWarningIcon from './display/icons/MosaicWarningIcon.vue';
import MosaicDataCreationList from './inputs/MosaicDataCreationList.vue';
import MosaicHelp from './display/MosaicHelp.vue';
import MosaicHelpSiteLink from './display/MosaicHelpSiteLink.vue';
import MosaicAlert from './display/MosaicAlert.vue';
import MosaicInfoAlert from './display/MosaicInfoAlert.vue';
import MosaicErrorIcon from './display/icons/MosaicErrorIcon.vue';
import MosaicForm from './forms/MosaicForm.vue';
import MosaicLoadingIcon from '../loading/MosaicLoadingIcon.vue';
import MosaicLoadingAndErrorCards from './loading/MosaicLoadingAndErrorCards.vue';
import MosaicConfigurePreviewPage from './configure-preview/MosaicConfigurePreviewPage.vue';
import MosaicNameEmailFilter from './filters/MosaicNameEmailFilter.vue';
import MosaicDateRangeFilter from './filters/MosaicDateRangeFilter.vue';
import MosaicSwitch from './inputs/MosaicSwitch.vue';
import MosaicPublishedDraftChip from './display/MosaicPublishedDraftChip.vue';
import MosaicPublishedDraftArchivedChip from './display/MosaicPublishedDraftArchivedChip.vue';
import MosaicJudgementDescriptorChip from './display/MosaicJudgementDescriptorChip.vue';
import MosaicCardAndListPage from './pages/MosaicCardAndListPage.vue';
import MosaicFilterListPage from './pages/MosaicFilterListPage.vue';
import LegacyMosaicInlineCreationListPage from './pages/LegacyMosaicInlineCreationListPage.vue';
import MosaicInlineCreationListPage from './pages/MosaicInlineCreationListPage.vue';
import MosaicTabCardPage from './pages/MosaicTabCardPage.vue';
import MosaicTabs from './mosaic-card/MosaicTabs.vue';
import MosaicTraineeCountChip from './display/MosaicTraineeCountChip.vue';
import MosaicCountChip from './display/MosaicCountChip.vue';
import MosaicInlineCreationList from './mosaic-list/MosaicInlineCreationList.vue';
import LegacyMosaicInlineCreationList from './mosaic-list/LegacyMosaicInlineCreationList.vue';
import MosaicNotificationIcon from './display/icons/MosaicNotificationIcon.vue';
import MosaicTargetCommentIcon from './display/icons/MosaicTargetCommentIcon.vue';
import MosaicReflectionCommentIcon from './display/icons/MosaicReflectionCommentIcon.vue';
import MosaicExpansionList from './mosaic-list/MosaicExpansionList.vue';
import MosaicIconBadge from './display/MosaicIconBadge.vue';
import MosaicCohortStudentsWrapper from '../monitoring/MosaicCohortStudentsWrapper.vue';
import MosaicChangePublishIcon from './display/icons/MosaicChangePublishIcon.vue';
import MosaicBudIcon from './display/icons/MosaicBudIcon.vue';
import MosaicGoReactIcon from './display/icons/MosaicGoReactIcon.vue';
import MosaicNasbttIcon from './display/icons/MosaicNasbttIcon.vue';
import MosaicTraineeIcon from './display/icons/MosaicTraineeIcon.vue';
import MosaicEctIcon from './display/icons/MosaicEctIcon.vue';
import MosaicCardSubtitle from './mosaic-card/MosaicCardSubtitle.vue';
import MosaicRoleCompletedChip from './display/MosaicRoleCompletedChip.vue';
import MosaicOrderedList from './mosaic-list/MosaicOrderedList.vue';
import LegacyMosaicOrderedList from './mosaic-list/LegacyMosaicOrderedList.vue';
import MosaicDialogCreationListPage from './pages/MosaicDialogCreationListPage.vue';
import MosaicIconNavigationButton from './buttons/MosaicIconNavigationButton.vue';
import MosaicCollapsableText from './display/MosaicCollapsableText.vue';
import MosaicCohortMonitoringFilters from './filters/MosaicCohortMonitoringFilters.vue';
import MosaicRouterLink from './navigation/MosaicRouterLink.vue';
import MosaicTimePicker from './inputs/MosaicTimePicker.vue';
import MosaicCloseIconButton from './buttons/MosaicCloseIconButton.vue';
import MosaicEvidenceIconButton from './buttons/MosaicEvidenceIconButton.vue';
import MosaicIconButtonWithMenu from './buttons/MosaicIconButtonWithMenu.vue';
import MosaicCreateEditTitle from './mosaic-card/MosaicCreateEditTitle.vue';
import MosaicSaveButtons from './mosaic-card/MosaicSaveButtons.vue';
import MosaicConfigurePreview from './configure-preview/MosaicConfigurePreview.vue';
import MosaicRefreshPageLink from './navigation/MosaicRefreshPageLink.vue';
import MosaicCardActionIconButton from './buttons/MosaicCardActionIconButton.vue';
import MosaicAvatar from './display/MosaicAvatar.vue';
import MosaicEmailField from './inputs/MosaicEmailField.vue';
import MosaicCheckboxMultiple from './inputs/MosaicCheckboxMultiple.vue';

// Remmeber to also update vue.d.ts to get type checking of the component
export function registerComponents(app: App<Element>) {
  app.component('MosaicCard', MosaicCard);
  app.component('MosaicMultiSectionCard', MosaicMultiSectionCard);
  app.component('MosaicSaveCard', MosaicSaveCard);
  app.component('MosaicSaveButtons', MosaicSaveButtons);
  app.component('MosaicCreateEditCardPage', MosaicCreateEditCardPage);
  app.component('MosaicStepperCard', MosaicStepperCard);
  app.component('MosaicCardTitle', MosaicCardTitle);
  app.component('MosaicCardSubtitle', MosaicCardSubtitle);
  app.component('MosaicCreateEditTitle', MosaicCreateEditTitle);
  app.component('MosaicCardHeading', MosaicCardHeading);
  app.component('MosaicCardSubheading', MosaicCardSubheading);
  app.component('MosaicCollapsableText', MosaicCollapsableText);

  app.component('MosaicTooltipChip', MosaicTooltipChip);
  app.component('MosaicDisabledTooltip', MosaicDisabledTooltip);
  app.component('MosaicHelp', MosaicHelp);
  app.component('MosaicHelpSiteLink', MosaicHelpSiteLink);

  app.component('MosaicIcon', MosaicIcon);
  app.component('MosaicInfoIcon', MosaicInfoIcon);
  app.component('MosaicWarningIcon', MosaicWarningIcon);
  app.component('MosaicErrorIcon', MosaicErrorIcon);
  app.component('MosaicNotificationIcon', MosaicNotificationIcon);
  app.component('MosaicTargetCommentIcon', MosaicTargetCommentIcon);
  app.component('MosaicReflectionCommentIcon', MosaicReflectionCommentIcon);
  app.component('MosaicChangePublishIcon', MosaicChangePublishIcon);
  app.component('MosaicNasbttIcon', MosaicNasbttIcon);
  app.component('MosaicTraineeIcon', MosaicTraineeIcon);
  app.component('MosaicEctIcon', MosaicEctIcon);
  app.component('MosaicBudIcon', MosaicBudIcon);
  app.component('MosaicGoReactIcon', MosaicGoReactIcon);

  app.component('MosaicPublishedDraftChip', MosaicPublishedDraftChip);
  app.component('MosaicPublishedDraftArchivedChip', MosaicPublishedDraftArchivedChip);

  app.component('MosaicJudgementDescriptorChip', MosaicJudgementDescriptorChip);
  app.component('MosaicTraineeCountChip', MosaicTraineeCountChip);
  app.component('MosaicCountChip', MosaicCountChip);

  app.component('MosaicPagination', MosaicPagination);

  app.component('MosaicForm', MosaicForm);
  app.component('MosaicRoleCompletedChip', MosaicRoleCompletedChip);

  app.component('MosaicAutocomplete', MosaicAutocomplete);
  app.component('MosaicCheckbox', MosaicCheckbox);
  app.component('MosaicCheckboxMultiple', MosaicCheckboxMultiple);
  app.component('MosaicDatePicker', MosaicDatePicker);
  app.component('MosaicTimePicker', MosaicTimePicker);
  app.component('MosaicColorPicker', MosaicColorPicker);
  app.component('MosaicPasswordTextField', MosaicPasswordTextField);
  app.component('MosaicPasswordSecurityLink', MosaicPasswordSecurityLink);
  app.component('MosaicPasswordHelp', MosaicPasswordHelp);
  app.component('MosaicSelect', MosaicSelect);
  app.component('MosaicTextArea', MosaicTextArea);
  app.component('MosaicTextField', MosaicTextField);
  app.component('MosaicQuillField', MosaicQuillField);
  app.component('MosaicFileUploadField', MosaicFileUploadField);
  app.component('MosaicJudgementSelect', MosaicJudgementSelect);
  app.component('MosaicJudgementSetSelect', MosaicJudgementSetSelect);
  app.component('MosaicRadioButtons', MosaicRadioButtons);
  app.component('MosaicSwitch', MosaicSwitch);
  app.component('MosaicEmailField', MosaicEmailField);

  app.component('MosaicDataCreationList', MosaicDataCreationList);
  app.component('MosaicExpandableUserList', MosaicExpandableUserList);
  app.component('MosaicTitle', MosaicTitle);
  app.component('MosaicList', MosaicList);
  app.component('MosaicListItem', MosaicListItem);
  app.component('MosaicTabItemList', MosaicTabItemList);
  app.component('MosaicListFiltersCard', MosaicListFiltersCard);
  app.component('MosaicInlineCreationList', MosaicInlineCreationList);
  app.component('MosaicInlineCreationListPage', MosaicInlineCreationListPage);
  app.component('LegacyMosaicInlineCreationList', LegacyMosaicInlineCreationList);
  app.component('MosaicOrderedList', MosaicOrderedList);
  app.component('LegacyMosaicOrderedList', LegacyMosaicOrderedList);
  app.component('MosaicDialogCreationListPage', MosaicDialogCreationListPage);
  app.component('MosaicExpansionList', MosaicExpansionList);

  app.component('MosaicLoadingIcon', MosaicLoadingIcon);
  app.component('MosaicLoadingAndErrorCards', MosaicLoadingAndErrorCards);
  app.component('MosaicLoadingCard', MosaicLoadingCard);
  app.component('MosaicLoadErrorCard', MosaicLoadErrorCard);
  app.component('MosaicLoadError', MosaicLoadError);

  app.component('MosaicPreviousAndNextArrowButtons', MosaicPreviousAndNextArrowButtons);
  app.component('MosaicBtnWithMenu', MosaicButtonWithMenu);
  app.component('MosaicIconBtnWithMenu', MosaicIconButtonWithMenu);
  app.component('MosaicBtn', MosaicButton);
  app.component('MosaicIconBtn', MosaicIconButton);
  app.component('MosaicIconBtnNumber', MosaicIconButtonNumber);
  app.component('MosaicCloseIconBtn', MosaicCloseIconButton);
  app.component('MosaicEvidenceIconBtn', MosaicEvidenceIconButton);
  app.component('MosaicDeleteIconBtn', MosaicDeleteIconButton);
  app.component('MosaicIconBadge', MosaicIconBadge);
  app.component('MosaicIconNavigationBtn', MosaicIconNavigationButton);
  app.component('MosaicCardActionIconBtn', MosaicCardActionIconButton);

  app.component('MosaicAlert', MosaicAlert);
  app.component('MosaicInfoAlert', MosaicInfoAlert);

  app.component('MosaicErrorAlert', MosaicErrorAlert);
  app.component('MosaicSnackbar', MosaicSnackbar);
  app.component('MosaicErrorSnackbar', MosaicErrorSnackbar);
  app.component('MosaicSuccessSnackbar', MosaicSuccessSnackbar);

  app.component('MosaicDialog', MosaicDialog);
  app.component('MosaicSaveDialog', MosaicSaveDialog);
  app.component('MosaicDeleteDialog', MosaicDeleteDialog);
  app.component('MosaicConfirmCohortActionUpdateDialog', MosaicConfirmCohortActionUpdateDialog);

  app.component('MosaicConfigurePreviewPage', MosaicConfigurePreviewPage);
  app.component('MosaicConfigurePreview', MosaicConfigurePreview);

  app.component('MosaicNameEmailFilter', MosaicNameEmailFilter);
  app.component('MosaicDateRangeFilter', MosaicDateRangeFilter);
  app.component('MosaicCohortMonitoringFilters', MosaicCohortMonitoringFilters);

  app.component('MosaicTabs', MosaicTabs);

  app.component('MosaicCardAndListPage', MosaicCardAndListPage);
  app.component('MosaicFilterListPage', MosaicFilterListPage);
  app.component('LegacyMosaicInlineCreationListPage', LegacyMosaicInlineCreationListPage);
  app.component('MosaicTabCardPage', MosaicTabCardPage);

  app.component('MosaicCohortStudentsWrapper', MosaicCohortStudentsWrapper);

  app.component('MosaicRouterLink', MosaicRouterLink);
  app.component('MosaicRefreshPageLink', MosaicRefreshPageLink);

  app.component('MosaicAvatar', MosaicAvatar);
}
