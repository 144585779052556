<template>
  <div>
    <mosaic-checkbox
      v-for="item in internalItems"
      :key="item.key"
      :name="`role-${item.value}`"
      :label="item.text"
      :model-value="item.value"
      :disabled="item.disabled"
      no-icon
      @update:model-value="($event: boolean) => updateValue(item.itemValue, $event)"
    />
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { watchEffect } from 'vue';

const props = withDefaults(
  defineProps<{
    items: Record<string, unknown>[];
    modelValue: number[] | string[];
    itemValue?: string;
    itemTitle?: string;
    disabler?: (item: Record<string, unknown>) => boolean;
  }>(),
  {
    itemValue: 'id',
    itemTitle: 'title',
  }
);

const emit = defineEmits<{
  (e: 'update:modelValue', value: unknown[]): void;
}>();

type Item = {
  itemValue: unknown;
  key: number;
  value: boolean;
  text: string;
  disabled: boolean;
};

function mapItems(): Item[] {
  return props.items.map((x, index) => {
    const itemValue = x[props.itemValue];
    return {
      itemValue: x[props.itemValue],
      disabled: props.disabler ? props.disabler(x) : false,
      key: index,
      value: (props.modelValue as unknown[]).includes(itemValue),
      text: x[props.itemTitle] as string,
    };
  });
}

const internalItems = ref<Item[]>([]);
watchEffect(() => (internalItems.value = mapItems()));

function updateValue(itemValue: unknown, event: boolean) {
  const item = internalItems.value.find(x => x.itemValue === itemValue);
  if (item) {
    item.value = event;
  }
  emit(
    'update:modelValue',
    internalItems.value.filter(r => r.value).map(r => r.itemValue)
  );
}
</script>
