<template>
  <mosaic-dialog
    :active="props.active"
    :title="title"
    :error-message="error"
    :close-button-text="closeText"
    :processing="processing"
    :persistent="processing"
    @update:active="emit('update:active', $event)"
  >
    <v-form ref="saveForm" v-bind="$attrs">
      <slot :on-keyup-enter="clickSave" :processing="processing"></slot>
    </v-form>
    <template #buttons>
      <mosaic-btn
        variant="text"
        :color="!$props.actionRedirect ? 'primary' : 'black'"
        :disabled="!props.canSave || processing"
        :loading="processing"
        :aria-label="action"
        @click.prevent="clickSave($event)"
        >{{ action }}</mosaic-btn
      >
      <mosaic-btn
        v-if="actionRedirect && redirectTo"
        variant="text"
        color="primary"
        :disabled="!props.canSave || processing"
        :loading="processing"
        :aria-label="actionRedirect"
        @click.prevent="clickSave($event, true)"
        >{{ actionRedirect }}</mosaic-btn
      >
    </template>
  </mosaic-dialog>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router';
import { isAxiosError } from 'axios';
import { ref } from 'vue';

const props = withDefaults(
  defineProps<{
    active: boolean;
    title: string;
    objectType: string;
    objectTypeIsPlural?: boolean;
    save: () => Promise<string | undefined | void>;
    saveErrors?: { [errorCode: string]: string };
    canSave?: boolean;
    action?: string;
    actionRedirect?: string;
    redirectTo?: object;
    closeText?: string;
  }>(),
  {
    action: 'save',
    canSave: true,
  }
);

const emit = defineEmits<{
  (e: 'update:active', active: boolean): void;
  (e: 'save'): void;
}>();

const processing = ref(false);
const error = ref('');
const router = useRouter();

const saveForm = ref<HTMLFormElement | null>(null);

async function validate(): Promise<boolean | void> {
  if (saveForm.value && (await saveForm.value.validate()).valid) {
    return true;
  } else {
    error.value = 'Please ensure all required fields are complete and valid.';
    return false;
  }
}

async function clickSave(e: Event, doRedirect: boolean = false) {
  const formValid = await validate();
  if (!formValid) return;
  if (processing.value || !props.canSave) return;

  processing.value = true;

  error.value = '';
  try {
    const saveError = await props.save();
    if (doRedirect && props.redirectTo) router.push(props.redirectTo);
    if (saveError) {
      error.value = saveError;
    } else {
      emit('save');
      emit('update:active', false);
    }
  } catch (e) {
    if (
      props.saveErrors &&
      isAxiosError(e) &&
      props.saveErrors[e.response?.data.errorCode || e.response?.data.error_code]
    ) {
      error.value = props.saveErrors[e.response?.data.errorCode || e.response?.data.error_code];
    } else {
      console.log(e);
      error.value = `Sorry, cannot ${props.action} ${props.objectTypeIsPlural ? 'these' : 'this'} ${
        props.objectType
      } at the moment`;
    }
  }
  processing.value = false;
}
</script>
