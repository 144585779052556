<template>
  <mosaic-dialog
    :active="active"
    :title="`Are you sure you want to update this ${actionNoun}?`"
    @update:active="emit('update:active', $event)"
    @close="emit('cancel')"
    @click-outside="emit('cancel')"
  >
    <slot></slot>
    <template v-if="showDeleteCheck">
      <mosaic-card-subheading class="mb-2" :class="{ 'mt-2': !defaultSlotEmpty() }"
        >{{ studentOrStaffNoun }} Removal</mosaic-card-subheading
      >
      <cohort-action-delete-dialog-information
        :action-noun="actionNoun"
        :action-target-title="actionTargetTitle"
        :status-items="actionStatusItems"
        :student-or-staff-ids-to-be-removed="studentOrStaffIdsToBeRemoved"
        :student-or-staff-noun="studentOrStaffNoun"
        :student-or-staff-noun-pluralised="studentOrStaffNounPluralised"
        :get-action-status="getActionStatus"
        :get-student-or-staff-details="getStudentOrStaffDetails"
      />
      <div class="mt-2">
        <div class="mb-2">
          This will permanently delete {{ studentOrStaffNoun }} data. Double check that you are happy for this data to
          be removed (and if you're not sure then contact support).
        </div>
        <div>If you're happy to proceed then type "permanently delete" below:</div>
        <mosaic-text-field
          v-model="permanentlyDelete"
          name="perm-delete"
          :placeholder="`Type &quot;permanently delete&quot; to delete ${studentOrStaffNoun} data`"
          label=""
          no-icon
        />
      </div>
    </template>

    <template #buttons>
      <v-btn
        variant="text"
        ripple
        :color="showDeleteCheck ? 'error' : 'primary'"
        :disabled="!canDelete"
        @click.prevent="confirmDelete()"
        >Confirm</v-btn
      >
    </template>
  </mosaic-dialog>
</template>

<script setup lang="ts">
import { computed, ref, useSlots } from 'vue';
import { isSlotEmpty } from '@/utils/mosaic-slots';
import CohortActionDeleteDialogInformation from '@/components/cohort-actions/CohortActionDeleteDialogInformation.vue';

const slots = useSlots();
const defaultSlotEmpty = () => isSlotEmpty(slots.default);

const props = withDefaults(
  defineProps<{
    active: boolean;
    actionNoun: string;
    actionTargetTitle: string;
    showDeleteCheck: boolean;
    actionStatusItems: { title: string; value: string; showDetails: boolean }[];
    studentOrStaffNoun: string;
    studentOrStaffIdsToBeRemoved?: number[];
    studentOrStaffNounPluralised: string;
    getActionStatus: (studentId: number) => string | undefined;
    getStudentOrStaffDetails: (id: number) =>
      | {
          id: number;
          userId: number;
          email: string;
          displayName: string;
          profilePictureUpdatedAt: string | null;
          hideProfilePicture: boolean;
        }
      | undefined;
  }>(),
  {
    studentOrStaffIdsToBeRemoved: () => [],
  }
);

const emit = defineEmits<{
  (e: 'cancel'): void;
  (e: 'confirm'): void;
  (e: 'update:active', active: boolean): void;
}>();

const permanentlyDelete = ref('');
const canDelete = computed(() => permanentlyDelete.value === 'permanently delete' || !props.showDeleteCheck);

function confirmDelete() {
  emit('confirm');
  emit('update:active', false);
}
</script>
