<template>
  <v-app-bar class="noTransform" :style="`margin-top: ${marginTop};`" :color="appBarColour">
    <v-app-bar-nav-icon v-if="!doNotShowNavigation" dark @click.stop="updateDrawer()" />
    <mosaic-router-link :to="{ name: 'UserHomePage' }">
      <img
        src="@/assets/mosaic_icon.png"
        style="height: 50px"
        alt="Mosaic icon"
        class="mr-2"
        :class="{ 'ml-2': doNotShowNavigation }"
      />
    </mosaic-router-link>
    <v-toolbar-title class="text-white" data-test-toolbar-title
      >Mosaic{{ isMosaicEarlyCareers ? ' Early Careers' : ''
      }}{{ selectedInstitution && !smallScreen ? ` - ${selectedInstitution.name}` : '' }}</v-toolbar-title
    >
    <div v-if="isImpersonating" class="ml-4 text-white">
      <v-icon class="mr-1 text-white">mdi-incognito</v-icon>
      Currently Impersonating ({{ user.email }})
    </div>

    <v-spacer />

    <div v-if="appUpdateAvailable" :class="{ 'mr-6': !smallScreen }" style="break-inside: avoid; min-width: 92px">
      <v-btn variant="outlined" color="white" @click.prevent="refreshPage()" class="mr-1">
        <div class="d-flex align-center">
          <v-icon>mdi-update</v-icon>
          <span v-if="!smallScreen" class="ml-1">Update Mosaic</span>
        </div>
      </v-btn>
      <mosaic-help icon-color="white"
        >A new version of Mosaic is available, click here to update Mosaic. When updating, any unsaved changes will be
        lost.</mosaic-help
      >
    </div>

    <active-targets-list
      v-if="canViewTargets"
      :load-error="activeTargetsError"
      :busy="activeTargetLoading"
      :active-targets="activeTargets"
      :load-targets="loadTargets"
      icon-color="'white'"
      :student-id="studentIdActiveTargets ? selectedStudent.id : userStudent.id"
      :selected-student="studentIdActiveTargets ? selectedStudent : null"
    />

    <div v-if="scopeSwitchers.length > 0 && !smallScreen" class="d-flex mr-2 ml-4" style="column-gap: 8px">
      <div v-for="switcher in scopeSwitchers" :id="'switcher-' + switcher.key" :key="switcher.tooltip">
        <v-menu location="bottom" :min-width="400" target="parent">
          <template #activator="{ props }">
            <mosaic-icon-btn :icon="switcher.icon" :tooltip="switcher.tooltip" icon-color="white" v-bind="props" />
          </template>
          <mosaic-card>
            <div>
              <div
                v-for="(scopeGroup, i) in switcher.scopeGroups"
                :key="scopeGroup.institution.id"
                :class="{ 'pb-4': i < switcher.scopeGroups.length - 1 }"
              >
                <div v-if="showScopeGroupInstitution && switcher.key !== 'institution'" class="font-weight-bold pb-2">
                  {{ scopeGroup.institution.name }}
                </div>

                <v-list class="py-0">
                  <v-list-item
                    v-for="scope in scopeGroup.scopes"
                    :key="scope.key || scope.id"
                    :active="false"
                    ripple
                    :class="{ 'pl-0': switcher.key === 'institution' || !showScopeGroupInstitution }"
                    :to="scope.to"
                    :title="scope.name"
                  >
                    <template #prepend>
                      <v-avatar>
                        <mosaic-icon
                          :icon="switcher.icon"
                          :color="isTheCurrentRoute(scope.to) ? 'accent' : undefined"
                        />
                      </v-avatar>
                    </template>

                    <template #append>
                      <v-list-item-action v-if="isTheCurrentRoute(scope.to)" :end="true">
                        <v-icon color="accent">mdi-radiobox-marked</v-icon>
                      </v-list-item-action>
                    </template>
                  </v-list-item>
                </v-list>
                <template v-if="scopeGroup.hasMore">
                  <mosaic-router-link
                    v-if="!isTheCurrentRoute({ name: 'TutorHomePage', params: { staffId: scopeGroup.staffId } })"
                    class="pb-2"
                    :to="{ name: 'TutorHomePage', params: { staffId: scopeGroup.staffId } }"
                    >See more</mosaic-router-link
                  >
                  <div v-else>See more on Dashboard (you're here)</div>
                </template>
              </div>
            </div>
          </mosaic-card>
        </v-menu>
      </div>
    </div>
    <v-divider
      vertical
      v-if="scopeSwitchers.length > 0 && !smallScreen"
      class="mx-2 my-5 border-opacity-75"
      color="white"
    />

    <div>
      <div>
        <v-menu v-if="user" v-model="notificationsMenu" :width="1200" target="parent" location="bottom">
          <template #activator="{ props, value }">
            <div class="px-2">
              <mosaic-icon-btn
                :icon="hasNewNotifications ? 'mosaic-notification' : 'bell'"
                :tooltip="hasNewNotifications ? `You have new Notifications` : 'View Notifications'"
                icon-color="white"
                color="white"
                :tooltip-disabled="value"
                v-bind="props"
                @click="loadNotifications(value)"
              />
            </div>
          </template>

          <mosaic-card>
            <mosaic-card-title>Notifications</mosaic-card-title>

            <div v-if="notifications.length === 0 && loadNotificationsError" class="pt-4">
              Sorry, cannot load your Notifications at the moment
            </div>

            <div v-else-if="notifications.length === 0 && loadNotificationsProcessing" class="pt-4">
              <v-skeleton-loader type="paragraph"></v-skeleton-loader>
            </div>

            <div v-else-if="notifications.length === 0" class="pt-4">You have no Notifications</div>

            <div v-else>
              <v-list lines="two" class="py-0">
                <template v-for="n in notifications.slice(0, 5)" :key="n.id">
                  <notification-list-item :notification="n" />
                  <v-divider class="my-2 mx-4" />
                </template>
              </v-list>
              <div class="d-flex justify-center pt-2">
                <mosaic-router-link :to="{ name: 'UserHomePage', query: { card: 'notifications' } }"
                  >See all Notifications ({{ notificationsUnreadCount }} unread)</mosaic-router-link
                >
              </div>
            </div>
          </mosaic-card>
        </v-menu>
      </div>
    </div>
    <div class="pr-2 py-2">
      <v-menu v-if="user" target="parent" location="bottom">
        <template #activator="{ props, value }">
          <div class="pointer pr-2" v-bind="props">
            <v-toolbar-title>
              <mosaic-avatar :user="user" border ignore-institution-config :hide-profile-picture="false" size="small" />
              <v-icon v-if="!value" color="white">mdi-chevron-down</v-icon>
              <v-icon v-else color="white">mdi-chevron-up</v-icon>
            </v-toolbar-title>
          </div>
        </template>
        <v-list>
          <v-list-item
            v-for="menuItem in menuItems"
            :key="menuItem"
            @click.prevent="menuFunction(menuItem)"
            :title="menuItem"
          />
        </v-list>
      </v-menu>
    </div>
    <ndt-dialog
      v-model:active="changePasswordDialog.active"
      title="Change Password"
      :error-message="changePasswordDialog.errorMessage"
      :on-close="() => (changePasswordDialog.successMessage = '')"
      @click-outside="changePasswordDialog.successMessage = ''"
    >
      <mosaic-password-help />
      <div class="py-2">After changing your password, you will be logged out of other browsers and devices.</div>
      <mosaic-password-text-field
        v-model="changePasswordDialog.oldPassword"
        name="oldPassword"
        label="Current Password"
      />
      <mosaic-password-text-field
        v-model="changePasswordDialog.newPassword"
        name="newPassword"
        label="New Password"
        :validate-password="true"
        @password-valid="passwordValid = $event"
      />
      <mosaic-password-text-field
        v-model="changePasswordDialog.newPasswordConfirmation"
        name="newPasswordConfirmation"
        label="New Password Confirmation"
        :rules="passwordConfirmationRules"
        @keyup.enter="submitChangePassword"
      />
      <div class="d-flex justify-end">
        <mosaic-password-security-link />
      </div>
      <mosaic-alert v-if="!!changePasswordDialog.successMessage" type="success" class="my-2">
        {{ changePasswordDialog.successMessage }}
      </mosaic-alert>
      <template v-if="!changePasswordDialog.success" #buttons>
        <v-btn
          variant="text"
          ripple
          :disabled="!canSubmit || changePasswordDialog.processing"
          :loading="changePasswordDialog.processing"
          @click.prevent="submitChangePassword()"
          >Submit</v-btn
        >
      </template>
    </ndt-dialog>
    <mosaic-support-dialog v-if="user && !user.isAdmin" v-model:active="supportDialog.active" />
  </v-app-bar>
</template>

<script>
import NdtDialog from '@/components/NdtDialog.vue';
import { mapGetters, mapState } from 'vuex';
import { validationsPass } from '@/utils/validations';
import MosaicSupportDialog from '@/components/library/dialogs/MosaicSupportDialog.vue';
import NotificationListItem from '@/components/notifications/NotificationListItem.vue';
import { mapStateProcessingAndError } from '@/store/map-store';
import { icons } from '@/utils/icons';
import _ from 'lodash';
import { findInstitutionLevelHomePage, getMentorTrainingPageRedirection } from '@/router/guard-methods';
import { findStudentLevelHomePage } from '@/utils/navigation';
import ActiveTargetsList from '../ActiveTargetsList.vue';
import { useActiveTargetsStore } from '@/stores/active-targets';
import { useProfessionalResourcesStore } from '@/stores/professional-resources';
import { isAuthTokenExpired } from '@/utils/auth';
import { isAxiosError } from 'axios';

export default {
  name: 'AppToolbar',
  components: { NdtDialog, MosaicSupportDialog, NotificationListItem, ActiveTargetsList },
  props: {
    doNotShowNavigation: {
      type: Boolean,
      default: false,
    },
    drawer: {
      type: Boolean,
      default: true,
    },
    marginTop: {
      type: String,
      required: true,
    },
  },
  emits: ['update:drawer'],
  data: function () {
    return {
      changePasswordDialog: {
        active: false,
        processing: false,
        oldPassword: '',
        newPassword: '',
        newPasswordConfirmation: '',
        success: true,
        errorMessage: '',
        successMessage: '',
      },
      supportDialog: {
        active: false,
      },
      passwordValid: false,
      passwordConfirmationRules: [
        v => (v && v === this.changePasswordDialog.newPassword) || 'Password confirmation should match password',
      ],
      notificationsMenu: false,
      notificationsPollInterval: null,
    };
  },
  setup() {
    const {
      activeTargets,
      activeTargetLoading,
      activeTargetsError,
      studentIdActiveTargets,
      actions: { loadTargets },
    } = useActiveTargetsStore();

    const {
      myProfessionalResources,
      actions: { loadProfessionalResources },
    } = useProfessionalResourcesStore();

    return {
      activeTargets,
      myProfessionalResources,
      loadProfessionalResources,
      activeTargetLoading,
      activeTargetsError,
      studentIdActiveTargets,
      loadTargets,
    };
  },
  computed: {
    ...mapState([
      'user',
      'isImpersonating',
      'selectedInstitution',
      'notificationsUnreadCount',
      'selectedStudent',
      'userStudent',
    ]),
    ...mapGetters([, 'isMosaicEarlyCareers', 'appUpdateAvailable', 'notifications']),
    ...mapStateProcessingAndError(['loadNotifications']),
    canSubmit() {
      return (
        this.changePasswordDialog.oldPassword &&
        this.changePasswordDialog.newPassword &&
        this.changePasswordDialog.newPasswordConfirmation &&
        validationsPass(this.passwordConfirmationRules, this.changePasswordDialog.newPasswordConfirmation) &&
        this.passwordValid
      );
    },
    canViewTargets() {
      if (this.user?.student) return true;
      return (
        (!this.selectedInstitution?.config.early_careers ||
          this.userStaffHasPermissionForSelectedStudent('ect.assessment.view')) &&
        this.studentIdActiveTargets
      );
    },
    menuItems() {
      const items = [];
      if (!this.$route.meta.noLayoutContainerNavigation) {
        if (!this.user.isAdmin) {
          items.push('Profile');
        }
        if (this.selectedInstitution?.config.show_professional_resources && this.myProfessionalResources.length > 0) {
          items.push('Professional Resources');
        }

        if (this.smallScreen && this.user.staff && this.user.staff.length > 1 && this.selectedInstitution) {
          items.push('Switch Institution');
        }

        if (!this.user.forceMicrosoftSso) {
          items.push('Change Password');
        }

        if (!this.user.isAdmin) {
          items.push('Contact Support');
        }
      }
      items.push('Logout');
      return items;
    },
    appBarColour() {
      if (this.isImpersonating) return 'pink';
      return 'primary';
    },
    hasNewNotifications() {
      return this.notifications.some(n => !n.seen);
    },
    scopeSwitchers() {
      if (!this.user || !this.user.staff) return [];

      const switchers = [];

      if (this.institutions.length > 1 || this.institutions.some(i => i.hasInstLevelStaffRoles)) {
        switchers.push({
          key: 'institution',
          icon: icons.institution,
          tooltip: 'My Institutions',
          showInsitutionGroups: false,
          scopeGroups: [{ scopes: this.institutions, institution: { id: 0, name: '' } }],
        });
      }

      if (this.cohorts.length > 0) {
        switchers.push({
          key: 'cohort',
          icon: icons.cohort,
          tooltip: 'My Cohorts',
          showInsitutionGroups: this.user.staff.length > 1,
          scopeGroups: this.cohorts,
        });
      }

      if (this.schools.length > 0) {
        switchers.push({
          key: 'school',
          icon: icons.school,
          tooltip: 'My Schools',
          showInsitutionGroups: this.user.staff.length > 1,
          scopeGroups: this.schools,
        });
      }

      if (
        this.ects.length > 0 &&
        (!this.selectedInstitution || !this.selectedInstitution.config.mentor_training_only)
      ) {
        switchers.push({
          key: 'ect',
          icon: this.trainees.length > 0 ? icons.ect : icons.student,
          tooltip: 'My ECTs',
          showInsitutionGroups: this.user.staff.length > 1,
          scopeGroups: this.ects,
        });
      }

      if (
        this.trainees.length > 0 &&
        (!this.selectedInstitution || !this.selectedInstitution.config.mentor_training_only)
      ) {
        switchers.push({
          key: 'trainee',
          icon: this.ects.length > 0 ? icons.trainee : icons.student,
          tooltip: 'My Trainees',
          showInsitutionGroups: this.user.staff.length > 1,
          scopeGroups: this.trainees,
        });
      }

      return switchers.reverse();
    },
    trainees() {
      const staffRoles = this.user.staff
        .filter(st => !st.institution.config.early_careers)
        .map(st =>
          st.staff_roles
            .filter(x => x.student_id)
            .map(x => ({
              id: x.student.id,
              name: x.student.display_name,
              to: findStudentLevelHomePage(st, x.student),
              institution: st.institution,
              staffId: st.id,
            }))
        )
        .flat();

      return this.groupRolesForTheSameScopeAndByInstitution(staffRoles);
    },
    ects() {
      const staffRoles = this.user.staff
        .filter(st => st.institution.config.early_careers)
        .map(st =>
          st.staff_roles
            .filter(x => x.student_id && (!x.student.ect || x.student.ect.status == 'active'))
            .map(x => ({
              id: x.student.id,
              name: x.student.display_name,
              to: findStudentLevelHomePage(st, x.student),
              institution: st.institution,
              staffId: st.id,
            }))
        )
        .flat();

      return this.groupRolesForTheSameScopeAndByInstitution(staffRoles);
    },
    schools() {
      const staffRoles = this.user.staff
        .map(st =>
          st.staff_roles
            .filter(x => x.school_id)
            .map(x => ({
              id: x.school.id,
              name: x.school.display_name,
              to: {
                name: 'TutorSchoolStudentsPage',
                params: { schoolId: x.school.id },
              },
              institution: st.institution,
              staffId: st.id,
            }))
        )
        .flat();

      return this.groupRolesForTheSameScopeAndByInstitution(staffRoles);
    },
    cohorts() {
      const staffRoles = this.user.staff
        .map(st =>
          st.staff_roles
            .filter(x => x.cohort_id)
            .map(x => {
              const to = { name: 'TutorStudentListPage', params: { cohortId: x.cohort.id } };
              if (st.institution.config.mentor_training_only)
                to.name = getMentorTrainingPageRedirection(st, {
                  id: x.cohort.id,
                  institution_id: st.institution.id,
                });
              return {
                id: x.cohort.id,
                name: x.cohort.name,
                to,
                institution: st.institution,
                staffId: st.id,
              };
            })
        )
        .flat();

      return this.groupRolesForTheSameScopeAndByInstitution(staffRoles);
    },
    institutions() {
      const staffRoles = this.user.staff.map(x => ({
        id: x.institution.id,
        name: x.institution.name,
        to: findInstitutionLevelHomePage(x),
        hasInstLevelStaffRoles: x.staff_roles.some(sr => sr.institution_id),
        staffId: x.id,
      }));

      return this.groupRolesForTheSameScope(staffRoles).sortBy('name');
    },
    showScopeGroupInstitution() {
      return this.user.staff && this.user.staff.length > 1;
    },
  },
  watch: {
    notificationsMenu(x) {
      // Clear on close
      if (!x && this.notifications.some(n => !n.seen)) {
        this.$store.dispatch('updateNotificationsLastSeen');
      }
    },
    user(x) {
      if (x) {
        this.startNotificationsPolling();
        this.loadProfessionalResources();
      }

      if (!x) {
        this.stopNotificationsPolling();
      }
    },
  },
  created() {
    if (this.user) {
      this.startNotificationsPolling();
      this.loadProfessionalResources();
    }
  },
  methods: {
    groupRolesForTheSameScope(staffRoles) {
      return staffRoles.reduce((dedupedStaffRoles, staffRole) => {
        const matchingScope = dedupedStaffRoles.find(x => x.id == staffRole.id);
        if (matchingScope) {
          return dedupedStaffRoles;
        } else {
          return [...dedupedStaffRoles, staffRole];
        }
      }, []);
    },
    groupByInstitution(scopes) {
      return Object.entries(_.groupBy(scopes, s => s.institution.id))
        .map(([_institutionId, scopes]) => {
          const institution = scopes[0].institution;
          const staffId = scopes[0].staffId;
          return {
            institution: {
              id: institution.id,
              name: institution.name,
            },
            staffId,
            scopes: scopes.sortBy('name').slice(0, 5),
            hasMore: scopes.length > 5,
          };
        })
        .sortBy('institution.name');
    },
    groupRolesForTheSameScopeAndByInstitution(staffRoles) {
      const scopes = this.groupRolesForTheSameScope(staffRoles);
      return this.groupByInstitution(scopes);
    },
    loadNotifications(notificationsOpen) {
      if (!notificationsOpen) {
        this.$store.dispatch('loadNotifications');
      }
    },
    startNotificationsPolling() {
      if (!this.notificationsPollInterval) {
        const tenMinutes = 10 * 60 * 1000;
        // There's currently a problem if multiple tabs try and refresh the same token at the same time
        // The local storage locking is meant to prevent that, but it isn't working in all occasions
        // Therefore offset the notifications interval (by up to a minute) so that multiple tabs opened at the same time don't all
        // try and refresh the token at the same time
        const offset = Math.random() * 60 * 1000;
        const interval = tenMinutes + offset;
        this.notificationsPollInterval = setInterval(async () => {
          // If it's a 401 we don't want the token to be automatically refreshed and we want the polling to stop
          // This try-catch and use of session storage isn't the most elegant, but this code is likely to change with websockets anyway
          if (isAuthTokenExpired()) return;
          try {
            await this.$store.dispatch('loadNotifications', { force: true, doNotRefreshToken: true, throwError: true });
          } catch (e) {
            if (isAxiosError(e) && e.response?.status == 401) {
              return;
            } else {
              throw e;
            }
          }
        }, interval);
      }
    },
    stopNotificationsPolling() {
      if (this.notificationsPollInterval) {
        clearInterval(this.notificationsPollInterval);
        this.notificationsPollInterval = null;
      }
    },
    refreshPage() {
      window.location.reload();
    },
    updateDrawer: function () {
      this.$emit('update:drawer', !this.drawer);
    },
    async submitChangePassword() {
      this.changePasswordDialog.processing = true;
      this.changePasswordDialog.errorMessage = '';
      this.changePasswordDialog.oldPasswordCorrect = true;
      this.changePasswordDialog.successMessage = '';
      try {
        const r = await this.$api.post('users/current/change-password', {
          old_password: this.changePasswordDialog.oldPassword,
          new_password: this.changePasswordDialog.newPassword,
        });
        if (r.data.success) {
          this.changePasswordDialog = {
            active: true,
            processing: false,
            oldPassword: '',
            newPassword: '',
            newPasswordConfirmation: '',
            oldPasswordCorrect: true,
            successMessage: 'Your password has been updated',
          };
        } else {
          if (r.data.old_password_correct) {
            this.changePasswordDialog.errorMessage =
              'Sorry, updating your password has failed. Please check the new passwords match and try again';
          } else {
            this.changePasswordDialog.errorMessage = 'Sorry, your current password does not match our records';
          }
          this.changePasswordDialog.processing = false;
        }
      } catch (e) {
        console.log(e);
        this.changePasswordDialog.errorMessage = 'Sorry, cannot update your password. Please try again later';
        this.changePasswordDialog.processing = false;
      }
    },

    menuFunction: function (menuItem) {
      switch (menuItem) {
        case 'Logout':
          this.$router.push({ name: 'LogoutPage' });
          break;
        case 'Profile':
          this.$router.push({ name: 'UserProfilePage' });
          break;
        case 'Professional Resources':
          this.$router.push({ name: 'UserHomePage' });
          break;
        case 'Switch Institution':
          this.$router.push({ name: 'UserHomePage' });
          break;
        case 'Change Password':
          this.changePasswordDialog.active = true;
          this.changePasswordDialog.success = false;
          break;
        case 'Contact Support':
          this.supportDialog.active = true;
          break;
      }
    },

    isTheCurrentRoute(to) {
      return this.$router.resolve(to).href.endsWith(this.$route.path);
    },
  },
};
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
</style>

<style>
.notification-subtitle-quill .ql-editor {
  padding: 0;
  padding-top: 8px;
}

.noTransform {
  transform: none !important;
}
</style>
