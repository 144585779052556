<template>
  <v-avatar
    :color="color.base"
    :size="size == 'profile-page' ? '100px' : size"
    :border="border"
    v-if="user.profilePictureUpdatedAt && showProfilePicture"
  >
    <v-img
      :alt="`Profile picture for ${user.displayName}`"
      :src="`users/${user.id}/profile-picture?updatedAt=${user.profilePictureUpdatedAt}`"
    >
      <template #error>
        <div :style="{ fontSize }" style="height: 100%" class="d-flex align-center justify-center">
          <span>{{ initialiseText(capitaliseFirstLetters(user.displayName), 2) }}</span>
        </div>
      </template>
    </v-img>
  </v-avatar>
  <v-avatar :color="color.base" :size="size == 'profile-page' ? '100px' : size" :border="border" v-else>
    <div :style="{ fontSize }" style="height: 100%" class="d-flex align-center justify-center">
      <span>{{ initialiseText(capitaliseFirstLetters(user.displayName), 2) }}</span>
    </div>
  </v-avatar>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import colors from 'vuetify/util/colors';
import { initialiseText, capitaliseFirstLetters } from '@/utils/text';
import { useInstitutionStore } from '@/stores/institution';

const props = withDefaults(
  defineProps<{
    user: {
      id: number;
      displayName: string;
      profilePictureUpdatedAt: string | null;
    };
    hideProfilePicture: boolean;
    size?: 'x-small' | 'small' | 'default' | 'x-large' | 'profile-page';
    // border color is configured via settings.scss and I think has to be done globally
    border?: boolean;
    ignoreInstitutionConfig?: boolean;
  }>(),
  {
    size: 'default',
  }
);

const { selectedInstitution } = useInstitutionStore();
const showProfilePicture = computed(() => {
  if (props.hideProfilePicture) return false;
  if (props.ignoreInstitutionConfig) return true;
  return selectedInstitution.value ? selectedInstitution.value.config.show_profile_pictures : true;
});

// Retrieve profile picture - use profile_picture_updated_at for cache busting
// If no profile picture then render letters
// Some sort of loading state?

const color = computed(() => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { ['shades']: _shade, ...avatarColors } = colors;
  const colorArray = Object.values(avatarColors);
  return colorArray[props.user.id % (colorArray.length - 1)];
});

const fontSize = computed(() => {
  switch (props.size) {
    case 'profile-page':
      return '36px';
    case 'x-large':
      return '22px';
    case 'small':
      return '12px';
    case 'x-small':
      return '12px';
    case 'default':
    default:
      return '14px';
  }
});
</script>
