<template>
  <div>
    <mosaic-loading-card v-if="loading && !hideLoading" :type="loadingType" />
    <mosaic-load-error-card
      v-else-if="loadError || error"
      :object-type="objectType"
      :object-type-is-plural="objectTypeIsPlural"
      @retry="internalLoad"
    />
    <slot v-else></slot>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import type { LoadingType } from './MosaicLoadingCard.vue';
import axios from 'axios';
import { useRoute, useRouter } from 'vue-router';

const props = defineProps<{
  objectType: string;
  objectTypeIsPlural?: boolean;
  load: (force?: boolean) => Promise<void>;
  loadingType?: LoadingType;
  triggerBackgroundLoad?: boolean;
  error?: boolean;
  hideLoading?: boolean;
}>();

const emit = defineEmits<{
  (e: 'update:triggerBackgroundLoad', trigger: boolean): void;
}>();

const router = useRouter();
const route = useRoute();

const loading = ref(true);
const loadError = ref(false);
async function internalLoad(setLoading = true, force = false) {
  if (setLoading) loading.value = true;
  loadError.value = false;
  try {
    await props.load(force);
  } catch (e) {
    if (axios.isAxiosError(e) && (e.response?.status === 404 || e.response?.status === 403)) {
      await router.replace({
        name: 'DoNotHaveAccessErrorPage',
        query: {
          to: route.fullPath,
          objectType: props.objectType,
          objectTypeIsPlural: props.objectTypeIsPlural.toString(),
        },
      });
    } else {
      console.log(e);
      loadError.value = true;
    }
  }
  loading.value = false;
}
internalLoad();

watch(
  () => props.triggerBackgroundLoad,
  async trigger => {
    if (trigger) {
      if (!loading.value) {
        await internalLoad(false, true);
      }
      emit('update:triggerBackgroundLoad', false);
    }
  }
);
</script>
